p {
    font-weight: 400;
    font-size: 100%;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

blockquote {
    border-left: 2px solid #ddd;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}

* {
    box-sizing: border-box;
    margin: 0;
    vertical-align: baseline;
    margin: 0;
    border: 0;
    font-family: 'Montserrat', sans-serif !important;
    /*  font-family: 'Roboto Mono', monospace !important; */
}

html,
body,
#root {
    min-height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    /*   font-family: 'Montserrat', sans-serif !important; */
    font-family: 'Montserrat', sans-serif !important;
    background-color: var(--tg-theme-bg-color);
    overflow: hidden;
}

.mainLayout {
    min-width: 100%;
    min-height: 100%;
    overflow: hidden;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}
